import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Title from "antd/lib/typography/Title";
import { Input, message, Select } from "antd";
import OrderHistory from "./OrderHistory";
import "antd/dist/antd.dark.min.css";
import { GetOrderMachineDetailsByOrderNo } from "./services/OrderService";

function App() {
  const [data, setData] = useState(null as unknown as any[]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [customer, setCustomer] = useState("");
  const [lang, setLang] = useState("tr");
  const { Option } = Select;

  const language: { [key: string]: any } = {
    tr: {
      title: "Agrotek Sipariş Takip Sistemi",
      customeraddon: "Müşteri Adı",
      ordernoaddon: "Sipariş No",
      customerplaceholder: "Lütfen Müşteri Adını giriniz.",
      ordernoplaceholder: "Lütfen Sipariş Numaranızı Giriniz.",
    },
    en: {
      title: "Agrotek Order Tracking System",
      customeraddon: "Customer Name",
      ordernoaddon: "Order No",
      customerplaceholder: "Please Enter Your Customer Name",
      ordernoplaceholder: "Please Enter Your Order Number",
    },
  };

  function getData(serial_number: string) {
    setSearchLoading(true);
    if (serial_number.length < 6) {
      message.info("Seri numarası 6 haneli olmalıdır.");
    }

    GetOrderMachineDetailsByOrderNo(serial_number, customer)
      .then((res) => {
        if (res.length > 0) {
          setData(res);
        }
        setSearchLoading(false);
      })
      .catch((err) => {
        setSearchLoading(false);
      });
  }

  return (
    <div className="App">
      <header className="App-header">
        <Select
          value={lang}
          style={{ width: 120, margin: 20, alignSelf: "flex-end" }}
          onChange={(value) => {
            setLang(value);
          }}
        >
          <Option value="tr">Türkçe</Option>
          <Option value="en">English</Option>
        </Select>
        <img
          src={logo}
          style={{ color: "white" }}
          className="App-logo"
          alt="logo"
        />
        <div style={{ margin: 16 }}>
          <Title>{language[lang].title}</Title>
          <Input
            size="large"
            value={customer}
            onChange={(e) => setCustomer(e.target.value)}
            addonBefore={language[lang].customeraddon}
            placeholder={language[lang].customerplaceholder}
            style={{ marginBottom: 10 }}
          ></Input>
          <Input.Search
            disabled={customer.length < 1}
            loading={searchLoading}
            onSearch={(data) => getData(data)}
            size="large"
            addonBefore={language[lang].ordernoaddon}
            placeholder={language[lang].ordernoplaceholder}
          />
        </div>
      </header>
      {(() => {
        return data?.map((machine, order) => {
          return (
            <OrderHistory
              serial_number={machine.serial_number}
              machine_id={machine.id}
              order={order + 1}
              description={machine.description}
              lang={lang}
            />
          );
        });
      })()}
    </div>
  );
}

export default App;
