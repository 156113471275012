import Axios from "axios";
//export const API_ADDRESS = "http://192.168.1.187:5000";
//export const API_ADDRESS = "https://agrotekapi.covisart.com:8443";
export const API_ADDRESS = 'https://factoryapi.covisart.com'

export interface IOrder {
  description: string;
  date: string;
  customer: string;
  phone_number: string;
  address: string;
  production_unit: string;
  order_no: string;
  order_tooker: string;
  order_amount: number;
  status: string;
  type: string;
  serial_number: string;
  cooler_type: string;
  tank_size: number;
  pump: string;
  year: string;
  tow_type: string;
  meme_type: string;
  meme_distance: string;
  arm_type: string;
  arm_length: string;
  chassis_color: string;
  caldron_type: string;
  caldron_color: string;
  commander: string;
  alt_features: string;
  brand: string;
}

export interface ITurboOrder extends IOrder {
  caldron_type: string;
  fan_radius: string;
  fan_type: string;
}

export interface ISleevedOrder extends IOrder {
  arm_length: string;
  arm_type: string;
  meme_distance: string;
  meme_type: string;
}

export interface GenericOrder extends ITurboOrder, ISleevedOrder {
  id: number;
  machine_id: number;
  last_location_date: string;
}

export class GetTaskRequestConfig {
  turbo: boolean = false;
  kollu: boolean = false;
  diger: boolean = false;
  test: boolean = false;
  bahce: boolean = false;
  depo: boolean = false;
  iptal: boolean = false;
  teslimat: boolean = false;
  onaybekliyor: boolean = false;
}

export async function GetOrderDetails(serial_number: string, customer: string) {
  const response = await Axios.get(`${API_ADDRESS}/api/order/getOrderById/${serial_number}`, {})
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function GetOrderMachineDetailsByOrderNo(orderNo: string, customer: string) {
  const response = await Axios.get(API_ADDRESS + "/api/order/GetOrderMachineDetailsByOrderNo/" + orderNo + "/" + customer, {})
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else return [];
    })
    .catch(() => {
      return [];
    });

  return response;
}

export async function GetOrderHistory(machine_id: number) {
  const response = await Axios.get(`${API_ADDRESS}/api/order/getOrderHistory/${machine_id}`, {})
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function GetArmHistory(machine_id: number) {
  const response = await Axios.get(`${API_ADDRESS}/api/order/getArmHistory/${machine_id}`, {})
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function GetFanHistory(machine_id: number) {
  const response = await Axios.get(`${API_ADDRESS}/api/order/getFanHistory/${machine_id}`, {})
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}

export async function GetChassisHistory(machine_id: number) {
  const response = await Axios.get(`${API_ADDRESS}/api/order/getChassisHistory/${machine_id}`, {})
    .then((res) => {
      if (res.status === 200 || res.status === 204) {
        return res.data;
      }
    })
    .catch(() => {
      return null;
    });

  return response;
}
