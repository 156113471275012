import { Spin, Timeline, Card, Row, Col } from "antd";
import { useEffect, useState } from "react";
import { ConvertDateTime, ConvertOrderLocation } from "./helper";
import {
  GetArmHistory,
  GetChassisHistory,
  GetFanHistory,
  GetOrderHistory,
} from "./services/OrderService";

enum PartTypes {
  arm,
  fan,
  chassis,
}

function MachinePartsHistory(props: {
  machine_id: number;
  type: PartTypes;
  lang: string;
}) {
  const { machine_id, type, lang } = props;
  const [data, setData] = useState(null as unknown as []);

  useEffect(() => {
    GetHistory();
  }, [machine_id]);

  function GetHistory() {
    switch (type) {
      case PartTypes.arm:
        GetArmHistory(machine_id).then((res) => {
          if (res?.length > 0) {
            setData(res);
          }
        });
        break;
      case PartTypes.fan:
        GetFanHistory(machine_id).then((res) => {
          if (res?.length > 0) {
            setData(res);
          }
        });
        break;
      case PartTypes.chassis:
        GetChassisHistory(machine_id).then((res) => {
          if (res?.length > 0) {
            setData(res);
          }
        });
        break;
    }
  }

  function renderTimeline() {
    return data?.map((timeline: any) => {
      return (
        <Timeline.Item
          key={"timelinekey" + timeline.row_created_date}
          label={ConvertDateTime(timeline.row_created_date, lang)}
        >
          <p>
            <b>{ConvertOrderLocation(timeline.location, lang)}</b>
          </p>
        </Timeline.Item>
      );
    });
  }

  return data ? (
    <Col xl={24} lg={12} sm={24}>
      <Card
        style={{ marginBottom: 5 }}
        title={
          type == PartTypes.arm
            ? lang == "tr"
              ? "Kol İşlem Geçmişi"
              : "Arm Transaction History"
            : type == PartTypes.chassis
            ? lang == "tr"
              ? "Şase İşlem Geçmişi"
              : "Chassis Transaction History"
            : lang == "tr"
            ? "Fan İşlem Geçmişi"
            : "Fan Transaction History"
        }
      >
        <Timeline mode="left" style={{ width: "100%" }}>
          {renderTimeline()}
        </Timeline>
      </Card>
    </Col>
  ) : null;
}

function OrderHistory(props: {
  machine_id: number;
  order: number;
  serial_number: string;
  lang: string;
  description: string;
}) {
  const { machine_id, order, serial_number, lang, description } = props;
  const [data, setData] = useState(null as unknown as []);

  useEffect(() => {
    GetHistory();
  }, [machine_id]);

  function GetHistory() {
    GetOrderHistory(machine_id).then((res) => {
      if (res) {
        setData(res);
      }
    });
  }

  function renderTimeline() {
    return data?.map((timeline: any) => {
      return (
        <Timeline.Item
          key={"timelinekey" + timeline.row_created_date}
          label={ConvertDateTime(timeline.row_created_date, lang)}
        >
          <p>
            <b>
              {ConvertOrderLocation(
                timeline.location,
                lang,
                timeline.planned_date
              )}
            </b>
          </p>
        </Timeline.Item>
      );
    });
  }

  return (
    <Card style={{ margin: 10 }} title={serial_number + ", " + description}>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {data ? (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Row>
              <Col xl={6} lg={12} sm={24}>
                <Card
                  title={
                    lang == "tr"
                      ? "Makine İşlem Geçmişi"
                      : "Machine Transaction History"
                  }
                  style={{ marginBottom: 5 }}
                >
                  <Timeline mode="left" style={{ width: "100%" }}>
                    {renderTimeline()}
                  </Timeline>
                </Card>
                <MachinePartsHistory
                  machine_id={machine_id}
                  type={PartTypes.chassis}
                  lang={lang}
                />
                <MachinePartsHistory
                  machine_id={machine_id}
                  type={PartTypes.fan}
                  lang={lang}
                />
                <MachinePartsHistory
                  machine_id={machine_id}
                  type={PartTypes.arm}
                  lang={lang}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <Spin size="large" />
        )}
      </div>
    </Card>
  );
}

export default OrderHistory;
